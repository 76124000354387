import { translate } from '@/domains/core/localization/translate';
export const {
  individualLoginText,
  individualLoginDescription,
  proLoginText,
  proLoginDescription,
  proPreRegisterDescription,
  proPreRegisterUrl
} = translate({
  "individualLoginText": "Je suis un particulier",
  "individualLoginDescription": "Acc\xE9der \xE0 mon compte",
  "proLoginText": "Je suis un Pro",
  "proLoginDescription": "Acc\xE9der aux prix Pro",
  "proPreRegisterDescription": "Pre-register",
  "proPreRegisterUrl": "http://pro.manomano.com/de/"
});