import { translate, translateFormatter, type RichTextFormatter } from '@/core/localization/translate';
export const {
  leonidasHelloText,
  leonidasLoginText
} = translate({
  "leonidasHelloText": "Bonjour",
  "leonidasLoginText": "Connectez-vous"
});
export const leonidasMobileLoginText = translateFormatter<{
  br: RichTextFormatter;
}>("Connectez <br></br>vous", undefined, undefined, "fr");